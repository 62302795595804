<template>
  <div class="GlobalTable">
    <div class="top_swith">
      <!-- 创建按钮 -->
      <div slot="AddSlot" style="margin-bottom: 20px;">
        <el-button icon="el-icon-plus" type="primary" size="mini" @click.native="openMakeStoreDialog">创建仓库</el-button>
      </div>
    </div>
    <!-- 仓库列表 -->
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns01" :data="this.seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="仓库名称" slot="warehouseName" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.warehouseName">
            {{ row.warehouseName }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>

      <el-table-column label="仓库使用名称" slot="warehouseShipName" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.warehouseShipName || "--" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="仓库负责人" slot="liabilityerName" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.liabilityerName || "--" }}-{{row.liabilityerMobile}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建人" slot="createAdminName" align="center" width="150px">
        <template slot-scope="{ row }">
          <span v-if="row.createAdminName">{{ row.createAdminName }}-{{ row.createAdminAcc }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" slot="createTime" width="150" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="仓库收件地址" slot="takeState" width="100" align="center">
        <template slot-scope="{ row }">
          <p>{{ row.addressNum }}</p>
          <p class="link primary" @click="modelConfigNext(row)">管理</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="ackTime" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="editStore(row)" style="margin-bottom: 6px">编辑
          </el-button>
          <el-button size="mini" type="warning" @click="handleBtn(row)" style="margin-bottom: 6px">收货地址随机数配置
          </el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!--创建编辑仓库对话框-->
    <el-dialog :title="storeDialogTitle" :visible.sync="storeDialogVisible" :close-on-click-modal="false"
      class="add-record-dialog" width="600px" @closed="handleDialogClosed">

      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <p class="box-title mt-10">仓库名称</p>
        <el-form-item label="仓库名称:" prop="warehouseName">
          <el-input v-model="ruleForm.warehouseName" placeholder="请输入仓库名称"></el-input>
        </el-form-item>
        <el-form-item label="仓库使用名称:" prop="warehouseShipName" label-width="110px">
          <el-input v-model="ruleForm.warehouseShipName" placeholder="请输入仓库使用名称，最多20个字，例如繁星分转中心（成都仓）"></el-input>
        </el-form-item>

        <div class="record-title">
          <span>说明：仓库使用名称用于繁星门店端店员发货标识、后台收货方查询条件标识</span>
        </div>
        <p class="box-title mt-10">仓库负责人</p>
        <el-form-item label="姓名:" prop="liabilityerName" >
          <el-input v-model="ruleForm.liabilityerName" placeholder="请输入负责人姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话:" prop="liabilityerMobile">
          <el-input v-model="ruleForm.liabilityerMobile" placeholder="请输入11位手机号"></el-input>
        </el-form-item>
      </el-form>
      <span class="box-title mt-10">仓库默认收货地址</span><span class="ml-10 perfectAddress"
        v-if="!ruleForm.receiveAddress.platformAddress" @click="wsReAdress">去完善></span>
      <div v-if="ruleForm.receiveAddress.platformAddress || ruleForm.receiveAddress.contactName || ruleForm.receiveAddress.contactPhone"
        class="ad_cstyle actionstyle">
        <div style="margin-bottom: 10px">
          <span style="margin-right: 10px;"><span>{{ ruleForm.receiveAddress.contactName }}</span>-<span>{{ ruleForm.receiveAddress.contactPhone
              }}</span></span><span class="edit" @click="bianArress(ruleForm)">修改></span>
        </div>
        <div>{{ ruleForm.receiveAddress.platformAddress }}{{ ruleForm.receiveAddress.houseAddress }}</div>
      </div>
     <div style="margin-top:10px;"><span class="box-title mt-10">仓库默认发货地址</span><span class="ml-10 perfectAddress"
        v-if="!ruleForm.sendAddress.platformAddress" @click="opensendShipaddressDialog">去完善></span></div> 
      <div v-if="ruleForm.sendAddress.platformAddress || ruleForm.sendAddress.contactName || ruleForm.sendAddress.contactPhone"
        class="ad_cstyle actionstyle">
        <div style="margin-bottom: 10px">
          <span style="margin-right: 10px;"><span>{{ ruleForm.sendAddress.contactName }}</span>-<span>{{ ruleForm.sendAddress.contactPhone
              }}</span></span><span class="edit" @click="mybianArress(ruleForm)">修改></span>
        </div>
        <div>{{ ruleForm.sendAddress.platformAddress }}{{ ruleForm.sendAddress.houseAddress }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="recordSuccess('ruleForm')">确定</el-button>
      </span>
    </el-dialog>
    <!-- 添加收货地址对话框 -->
    <el-dialog :title="adFlag" :visible.sync="shipaddressDialogVisible"
      :close-on-click-modal="false" width="700px" @closed="sdClosed">
      <p style="color: #ff8080;margin-bottom: 20px;font-size: 12px;">
          说明：收货地址用于门店发货寄往平台使用
        </p>
      <el-form :model="actioAdress" :rules="adRulesDialog" ref="adrressruleForm" label-width="92px"
        class="demo-ruleForm">
        <el-form-item label="收货人姓名" prop="contactName">
          <el-input type="text" v-model.trim="actioAdress.contactName" style="border: none"
            placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="收货人电话" prop="contactPhone">
          <el-input type="text" v-model.trim="actioAdress.contactPhone" 
            style="border: none" placeholder="请输入收货人电话"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="platformAddress">
          <el-input type="text" v-model.trim="actioAdress.platformAddress" style="border: none"
            placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="门牌号" prop="houseAddress">
          <el-input type="text" maxlength="20" v-model.trim="actioAdress.houseAddress" style="border: none"
            placeholder="请输入门牌号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="shipaddressDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="adressConfirmDialog('adrressruleForm')">确 定</el-button>
      </span>
    </el-dialog>
        <!-- 收货地址管理添加收货地址对话框 -->
        <el-dialog title="添加收货地址" :visible.sync="shipaddressDialogVisible1"
      :close-on-click-modal="false" width="700px" @closed="sdClosed12">
      <p style="color: #ff8080;margin-bottom: 20px;font-size: 12px;">
          说明：收货地址用于门店发货寄往平台使用
        </p>
      <el-form :model="actioAdress1" :rules="adRulesDialog1" ref="adrressruleForm1" label-width="92px"
        class="demo-ruleForm">
        <el-form-item label="收货人姓名" prop="contactNames">
          <el-input type="text" v-model.trim="actioAdress1.contactNames" style="border: none"
            placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="收货人电话" prop="contactPhones">
          <el-input type="text" v-model.trim="actioAdress1.contactPhones" 
            style="border: none" placeholder="请输入收货人电话"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="platformAddresss">
          <el-input type="text" v-model.trim="actioAdress1.platformAddresss" style="border: none"
            placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="门牌号" prop="houseAddresss">
          <el-input type="text" maxlength="20" v-model.trim="actioAdress1.houseAddresss" style="border: none"
            placeholder="请输入门牌号"></el-input>
        </el-form-item>
        <el-form-item label="动态口令" prop="commands">
          <el-input type="text" maxlength="20" v-model.trim="actioAdress1.commands" style="border: none"
            placeholder="请输入动态口令"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="sdClosed12">取 消</el-button>
        <el-button size="small" type="primary" @click="adressConfirmDialog2('adrressruleForm1')">确 定</el-button>
      </span>
    </el-dialog>
    <!--添加发货地址-->
    <el-dialog :title="senddresstitle" :visible.sync="sendShipaddressDialogVisible "
      :close-on-click-modal="false" width="700px" @closed="closeshipaddressDialogVisible">
      <p style="color: #ff8080;margin-bottom: 20px;font-size: 12px;">
          说明：发货地址用于分转后由仓库寄往回收商时快递取件使用
        </p>
      <el-form :model="sendactioAdress" :rules="sendadRulesDialog1" ref="sendadrressruleForm" label-width="92px"
        class="demo-ruleForm">
        <el-form-item label="发货人姓名" prop="sendcontactName">
          <el-input type="text" v-model.trim="sendactioAdress.sendcontactName" style="border: none"
            placeholder="请输入发货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="发货人电话" prop="sendcontactPhone">
          <el-input type="text" v-model.trim="sendactioAdress.sendcontactPhone" 
            style="border: none" placeholder="请输入发货人电话"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="sendplatformAddress">
          <el-input type="text" v-model.trim="sendactioAdress.sendplatformAddress" style="border: none"
            placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="门牌号" prop="sendhouseAddress">
          <el-input type="text" maxlength="20" v-model.trim="sendactioAdress.sendhouseAddress" style="border: none"
            placeholder="请输入门牌号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeshipaddressDialogVisible">取 消</el-button>
        <el-button size="small" type="primary" @click="adressConfirmDialog1('sendadrressruleForm')">确 定</el-button>
      </span>
    </el-dialog>
        <!--编辑收货地址管理编辑收货地址-->
        <el-dialog :title="senddresstitle1" :visible.sync="sendShipaddressDialogVisible1 "
      :close-on-click-modal="false" width="700px" @closed="closeshipaddressDialogVisible1">
      <p style="color: #ff8080;margin-bottom: 20px;font-size: 12px;">
          说明：收货地址用于分转后由仓库寄往回收商时快递取件使用
        </p>
      <el-form :model="sendactioAdress" :rules="sendadRulesDialog" ref="sendadrressruleForm1" label-width="92px"
        class="demo-ruleForm">
        <el-form-item label="收货人姓名" prop="sendcontactName">
          <el-input type="text" v-model.trim="sendactioAdress.sendcontactName" style="border: none"
            placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="收货人电话" prop="sendcontactPhone">
          <el-input type="text" v-model.trim="sendactioAdress.sendcontactPhone" 
            style="border: none" placeholder="请输入收货人电话"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="sendplatformAddress">
          <el-input type="text" v-model.trim="sendactioAdress.sendplatformAddress" style="border: none"
            placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="门牌号" prop="sendhouseAddress">
          <el-input type="text" maxlength="20" v-model.trim="sendactioAdress.sendhouseAddress" style="border: none"
            placeholder="请输入门牌号"></el-input>
        </el-form-item>
        <el-form-item label="动态口令" prop="sendcommand">
          <el-input v-model="sendactioAdress.sendcommand" placeholder="请输入谷歌动态口令"
            oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeshipaddressDialogVisible22">取 消</el-button>
        <el-button size="small" type="primary" @click="adressConfirmSubmit('sendadrressruleForm1')">确 定</el-button>
      </span>
    </el-dialog>
    <!--操作提示对话框-->
    <el-dialog title="操作提示" :visible.sync="editShow" :close-on-click-modal="false" width="550px"
      @closed="detaleDialogClosed">
      <p style="color: red; margin-bottom: 10px" v-if="storeDialogTitle !== '创建仓库'">
        说明：修改后，将立刻生效
      </p>
      <p style="color: red; margin-bottom: 10px" v-if="storeDialogTitle == '创建仓库'">
        说明：请核对好仓库信息，添加后立刻生效
      </p>
      <div class="cance-tip">
        <div class="tip-sure" v-if="storeDialogTitle !== '创建仓库'"
          style="text-align:center;margin-bottom: 25px;font-size: 22px">
          是否确认修改仓库信息
        </div>
        <div class="tip-sure" v-if="storeDialogTitle == '创建仓库'"
          style="text-align:center;margin-bottom: 25px;font-size: 22px">
          是否确认添加仓库？
        </div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="editShow = false">取消</el-button>
        <el-button type="primary" :loading="putBtnLoading" @click.native="forbidSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 收货地址管理 -->
    <el-dialog title="收货地址管理" :visible.sync="adressDialogVisible" :close-on-click-modal="false" width="700px" @close="closeAddressManage">
      <div style="margin-bottom: 20px">
        <span>收货仓库：</span>
        <el-select clearable size="small" @change="warChange" v-model="warehouseId" placeholder="请选择仓库" filterable>
          <el-option v-for="item in entrepotList" :key="item.id" :label="item.warehouseName"
            :value="item.id"></el-option>
        </el-select>
      </div>
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
        <p style="color: #ff8080">说明：最多可添加6个收货地址</p>
        <el-button size="small" type="primary" @click="addAdrress">+ 添加地址</el-button>
      </div>
      <div class="ad_cstyle actionstyle" v-for="(item, index) in adressList" :key="index">
        <div class="adc_top" style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          ">
          <div style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            ">
            <div class="adc_line"></div>
            <span v-if="item.isDefault">默认地址</span>
            <span v-else>地址{{ index + 1 }}</span>
            <div class="xiugai" v-if="!item.isDefault">
              <el-button type="text" @click="bianArress1(item)">编辑</el-button>
            </div>
          </div>
          <p v-if="!item.isDefault" style="color: #ff8080; font-size: 18px" @click="deteleAdrress(item)">
            <i class="el-icon-delete"></i>
          </p>
        </div>
        <div style="margin-bottom: 10px">
          {{ item.contactName }}-{{ item.contactPhone }}
        </div>
        <div>{{ item.platformAddress }}{{ item.houseAddress || " " }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeAddressManage">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 删除提示 -->
    <el-dialog title="操作提示" :visible.sync="enableShow" :close-on-click-modal="false" width="400px"
      @closed="enableClosed">
      <div class="enable_center">是否确认删除平台的收货地址？</div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')" v-model="deAdreList.command"
          placeholder="请输入谷歌动态口令"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="enableShow = false">取消</el-button>
        <el-button type="primary" @click.native="enableSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!--收货地址随机数弹框-->
    <el-dialog :title="randomTitle" :visible.sync="pushFactorSetShow" :close-on-click-modal="false"
      @close="handleClose" >
      <p class="tip" style="margin-bottom: 20px;">
        说明：收货地址编码仅在使用京东快递时生效
      </p>
      <el-table border ref="tableEle" size="small" :header-cell-style="{ background: '#E4ECFD', padding: 0 }"
        :data="gridData">
        <el-table-column label="收货地址姓名、地址随机数配置" align="center">
          <el-table-column v-for="(item, index) in tableColumns" :key="item.id" :prop="item.prop" align="center"
            :label="item.label" >
            <template slot="header" >
              <div v-if="index>0"><span style="margin-right: 5px;">{{item.label}}</span><span class="link primary" @click="seeAddress(item)">查看</span></div>
      </template>
            <template slot-scope="scope">
              <span v-if="index == 0">
                {{ scope.row.name }}
              </span>
              <span v-if="scope.$index == 0 && index > 0">
                <div style="display:flex;align-items: center;"> <div
                    style="margin-right: 5px;font-size: 14px;width: 150px;">随机数上限值</div><el-input 
                    placeholder="请输入四位数字" size="mini" style="width:200px" v-model="item.randomMax" @input="(val) => item.randomMax = val.replace(/[^\d]/g, '')" maxlength="4"></el-input></div>
              </span>
              <span v-if="scope.$index > 0 && index > 0">
                <div><span style="margin-right: 10px;margin-bottom: 10px;">姓名随机数</span><el-switch v-model="item[scope.row.key]"></el-switch></div>
                <div><span style="margin-right: 10px">地址随机数</span><el-switch v-model="item[scope.row.secondKey]"></el-switch></div>
              </span>
            </template>
          </el-table-column>
        </el-table-column>

      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="pushFactorSetShow = false">取 消</el-button>
        <el-button type="primary" @click="pushFactorSetSubmit">确 定</el-button>
      </div>
    </el-dialog>
        <!--随机数设置提示对话框-->
        <el-dialog title="操作提示" :visible.sync="randomShow" :close-on-click-modal="false" width="550px"
      @closed="randomDialogClosed">
      <p style="color: red; margin-bottom: 10px">
        说明：修改后立即生效，请确认设置
      </p>
      <div class="cance-tip">
        <div class="tip-sure"
          style="text-align:center;margin-bottom: 10px;font-size: 16px">
          是否确认修改{{submitRandomTitle}}随机数配置？
        </div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input v-model="randomCommand" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="randomShow = false">取消</el-button>
        <el-button type="primary"  @click.native="randomSubmit">确定</el-button>
      </span>
    </el-dialog>
        <!-- 查看设置随机数收货地址管理 -->
        <el-dialog
      title="查看地址"
      :visible.sync="randomAddressDialogVisible"
      :close-on-click-modal="false"
      width="700px"
    >
    <div
        class="ad_cstyle actionstyle"

      >
        <div
          class="adc_top"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <div class="adc_line"></div>
            <span >{{randomAddress.addressName}}</span>
          </div>
        </div>
        <div style="margin-bottom: 10px">
          {{ randomAddress.contactName }}-{{ randomAddress.contactPhone }}
        </div>
        <div>{{ randomAddress.platformAddress}}{{ randomAddress.houseAddress || " " }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment, { max } from "moment";
import { http } from "../../../utils/request/storeManage";
import { Message } from "element-ui";
export default {
  name: "Table",
  data() {
    return {
      loading: false,
      seachDataList: [],//返回的数据
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumns01: [
        { slotName: "warehouseName" },
        { slotName: "warehouseShipName" },
        { slotName: "liabilityerName" },
        { slotName: "createAdminName" },
        { slotName: "createTime" },
        // { slotName: "outOrderNo" },
        { slotName: "takeState" },
        { slotName: "ackTime" },
      ],
      // 弹框
      storeDialogTitle: "创建仓库",//仓库弹窗名称
      storeDialogVisible: false,
      ruleForm: {
        warehouseName: "",
        liabilityerName: "",
        liabilityerMobile: null,
        warehouseShipName: "",
        receiveAddress:{
          contactName: "",
        contactPhone: "",
        houseAddress: "",
        platformAddress: "",
        },
        sendAddress:{
          contactName: "",
        contactPhone: "",
        houseAddress: "",
        platformAddress: "",
        }
      },
      rules: {
        warehouseName: [
          {
            required: true,
            message: "请输入仓库名称,最多8个字",
            trigger: "change",
            max: 8
          },
        ],
        liabilityerName: [
          { required: true, message: "请输入负责人姓名", trigger: "change" },
        ],
        liabilityerMobile: [
          {
            required: true,
            message: " 请输入11位手机号",
            trigger: "blur",
          },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'change' }
        ],
        warehouseShipName: [
          {
            required: true,
            message: "请输入仓库使用名称，最多20个字，例如繁星分转中心（成都仓）",
            trigger: "change",
            max: 20
          },
        ],
        
      },
      shipaddressDialogVisible: false,
      sendShipaddressDialogVisible:false,
      //添加编辑地址
      actioAdress: {
        contactName: "",
        contactPhone: "",
        houseAddress: "",
        platformAddress: "",
      },
      actioAdress1: {
        contactNames: "",
        contactPhones: "",
        houseAddresss: "",
        platformAddresss: "",
        commands:""
      },
      sendactioAdress: {
        sendcontactName: "",
        sendcontactPhone: "",
        sendhouseAddress: "",
        sendplatformAddress: "",
        sendcommand: ""
      },
      adRulesDialog: {
        command: [
          {
            required: true,
            trigger: "blur",
            message: "请输入动态口令",
          },
        ],
        contactName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入收货人姓名",
          },
        ],
        contactPhone: [
          {
            required: true,
            trigger: "blur",
            message: "请输入收货人电话",
          },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'change' }
        ],
        platformAddress: [
          {
            required: true,
            trigger: "blur",
            message: "请输入详细地址",
          },
        ],
      },
      adRulesDialog1: {
        commands: [
          {
            required: true,
            trigger: "blur",
            message: "请输入动态口令",
          },
        ],
        contactNames: [
          {
            required: true,
            trigger: "blur",
            message: "请输入收货人姓名",
          },
        ],
        contactPhones: [
          {
            required: true,
            trigger: "blur",
            message: "请输入收货人电话",
          },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'change' }
        ],
        platformAddresss: [
          {
            required: true,
            trigger: "blur",
            message: "请输入详细地址",
          },
        ],
      },
      sendadRulesDialog: {
        sendcommand: [
          {
            required: true,
            trigger: "blur",
            message: "请输入动态口令",
          },
        ],
        sendcontactName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入发货人姓名",
          },
        ],
        sendcontactPhone: [
          {
            required: true,
            trigger: "blur",
            message: "请输入发货人电话",
          },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'change' }
        ],
        sendplatformAddress: [
          {
            required: true,
            trigger: "blur",
            message: "请输入详细地址",
          },
        ],
      },
      sendadRulesDialog1: {
        sendcommand: [
          {
            required: true,
            trigger: "blur",
            message: "请输入动态口令",
          },
        ],
        sendcontactName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入发货人姓名",
          },
        ],
        sendcontactPhone: [
          {
            required: true,
            trigger: "blur",
            message: "请输入发货人电话",
          },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'change' }
        ],
        sendplatformAddress: [
          {
            required: true,
            trigger: "blur",
            message: "请输入详细地址",
          },
        ],
      },
      editShow: false,
      command: "",
      putBtnLoading: false,
      adressDialogVisible: false,
      warehouseId: "",
      entrepotList: [],
      adressList: [],
      deAdreList: {
        addressId: "",
        command: "",
      },
      enableShow: false,
      formData: {
        addressList: [],
        command: "",
        companyId: "",
        contactName: "",
        contactPhone: "",
        effectiveTime: "",
        id: "",
        merchants: [],
        editaWareHouseId: "",
        isClickAdd: false,
      },
      pushFactorSetShow: false,//随机数弹框,
      randomTitle: "",//随机数弹框标题,
      gridData: [{ name: "发货渠道", }, { name: "小米",key: 'xmContactRandom',secondKey:"xmAddressRandom" }, { name: "一根线",key: 'ygxContactRandom',secondKey:"ygxAddressRandom" }, { name: "新采货侠",key:"chxContactRandom",secondKey:"chxAddressRandom" }, { name: "闪回收",key:"shanhsContactRandom",secondKey:"shanhsAddressRandom" }, { name: "繁星平台后台（自动发货、手动发货）" ,key:"platformContactRandom",secondKey:"platformAddressRandom"}, { name: "门店端小程序发货",key:"storeContactRandom",secondKey:"storeAddressRandom" }],//随机数表格固定数据
      tableColumns: [],//动态列,
      randomShow:false,//随机数提交弹框,
      randomCommand:"",
      randomstoreid:"",
      addressCodeList:[],
      randomAddress:{},
      randomAddressDialogVisible:false,
      senddresstitle:"",//发货对话框标题
      adFlag:"",
      isClickManage:false,//是否点击了管理,
      senddresstitle1:"",
      sendShipaddressDialogVisible1:false,
      id:"",
      shipaddressDialogVisible1:false,
      submitRandomTitle:""
    };
  },
  created() {
    this.handleCurrentChange();
    this.getwarehouseSelectList()
  },
  mounted(){
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    //入库表格切换页面
    handleCurrentChange(val) {
      console.log(this.SeachParams, "this.SeachParams")
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        warehouseId: this.SeachParams.warehouseId,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        warehouseShipName: this.SeachParams.warehouseShipName,
        liabilityerName: this.SeachParams.liabilityerName,
      };
      this.loading = true;
      http.storeList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }

      }).finally(() => {

      });
    },
    //创建或编辑仓库点击取消按钮弹框隐藏
    handleDialogClosed() {
      console.log(123)
     this.editaWareHouseId=""
      // this.$refs["ruleForm"].clearValidate();
      this.$refs.ruleForm.resetFields();
      this.storeDialogVisible = false
    },
    //创建编辑仓库提交
    recordSuccess(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (this.ruleForm.receiveAddress.contactName == "") {
            this.$message.error("请完善收货地址");
            return false
          }
          if (this.ruleForm.sendAddress.contactName == "") {
            this.$message.error("请完善发货地址");
            return false
          }
          this.editShow = true
          console.log(this.ruleForm);
        } else {
          return false;
        }
      });
    },
    editStore(row) {
      console.log(row.id)
      this.storeDialogVisible = true
      this.storeDialogTitle = "编辑仓库"
      this.editaWareHouseId = row.id
      http.storeDetail({ warehouseId: row.id }).then(res => {
        console.log(res)
        this.ruleForm.liabilityerMobile = res.data.liabilityerMobile
        this.ruleForm.liabilityerName = res.data.liabilityerName
        this.ruleForm.warehouseName = res.data.warehouseName
        this.ruleForm.warehouseShipName = res.data.warehouseShipName
        this.ruleForm.receiveAddress.contactName = res.data.receiveAddress.contactName
        this.ruleForm.receiveAddress.contactPhone = res.data.receiveAddress.contactPhone
        this.ruleForm.receiveAddress.houseAddress = res.data.receiveAddress.houseAddress
        this.ruleForm.receiveAddress.platformAddress = res.data.receiveAddress.platformAddress
        this.ruleForm.sendAddress.contactName=res.data.sendAddress.contactName
        this.ruleForm.sendAddress.contactPhone=res.data.sendAddress.contactPhone
        this.ruleForm.sendAddress.houseAddress=res.data.sendAddress.houseAddress
        this.ruleForm.sendAddress.platformAddress=res.data.sendAddress.platformAddress
      })
    },
    sdClosed() {
      this.$refs["adrressruleForm"].clearValidate();
    },
    sdClosed12() {
      this.shipaddressDialogVisible1=false
      this.actioAdress1.contactNames=""
      this.actioAdress1.contactPhones=""
      this.actioAdress1.houseAddresss=""
      this.actioAdress1.platformAddresss=""
      this.actioAdress1.command=""
      this.$refs["adrressruleForm1"].clearValidate();
    },
    //添加、编辑收货地址提交
    adressConfirmDialog(formName) {
console.log(123)
console.log(this.isClickAdd,"this.isClickAdd")
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(111)
          this.ruleForm.receiveAddress.contactName = this.actioAdress.contactName
          this.ruleForm.receiveAddress.contactPhone = this.actioAdress.contactPhone
          this.ruleForm.receiveAddress.houseAddress = this.actioAdress.houseAddress
          this.ruleForm.receiveAddress.platformAddress = this.actioAdress.platformAddress

          if (this.editaWareHouseId&&this.isClickManage) {
            console.log(8888)
            _api
              .savePlatformAddress({
                warehouseId: this.editaWareHouseId,
                ...this.actioAdress,
              })
              .then((res) => {
                console.log(res,"res")
                this.pageNum = 1
                this.shipaddressDialogVisible = false;
                this.getplantAddress();
                this.handleCurrentChange();
                this.$refs["adrressruleForm"].clearValidate();
                this.$refs["sendadrressruleForm"].clearValidate();
              });
          }
          if(!this.editaWareHouseId){
            console.log(123)
          }
          this.cleradreww();
        } else {
          return false;
        }
      });
    },
    adressConfirmDialog2(formName) {
console.log(123)
console.log(this.isClickAdd,"this.isClickAdd")
console.log(this.warehouseId,"editaWareHouseId")
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(111)
          _api
              .savePlatformAddress({
                warehouseId: this.warehouseId,
                contactName:this.actioAdress1.contactNames,
                contactPhone:this.actioAdress1.contactPhones,
                houseAddress:this.actioAdress1.houseAddresss,
                platformAddress: this.actioAdress1.platformAddresss,
                command:this.actioAdress1.commands,
              })
              .then((res) => {
                console.log(res,"res")
                this.pageNum = 1
                this.shipaddressDialogVisible1 = false;
                this.getplantAddress();
                this.handleCurrentChange();
      this.actioAdress1 = {
      contactNames: "",
      contactPhones: "",
        houseAddresss: "",
        platformAddresss: "",
        commands: ""
      };
      this.$refs["adrressruleForm1"].clearValidate();
              });
        } else {
          return false;
        }
      });
    },
    adressConfirmDialog1(formName) {
console.log(this.sendactioAdress.sendcontactName,1)
console.log(this.sendactioAdress.sendcontactPhone,2)
console.log(this.sendactioAdress.sendplatformAddress,3)
console.log(this.sendactioAdress.sendhouseAddress,4)
this.$refs[formName].validate((valid) => {
  if (valid) {
    console.log(valid)
    console.log(this.isClickAdd)
    
          this.ruleForm.sendAddress.contactName = this.sendactioAdress.sendcontactName
          this.ruleForm.sendAddress.contactPhone = this.sendactioAdress.sendcontactPhone
          this.ruleForm.sendAddress.platformAddress = this.sendactioAdress.sendplatformAddress
          this.ruleForm.sendAddress.houseAddress = this.sendactioAdress.sendhouseAddress
    this.cleradreww1();
  } else {
    return false;
  }
});
},
//修改地址管理提交
adressConfirmSubmit(formName){
  console.log(this.editaWareHouseId)
  console.log(this.isClickManage) 
  this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(111)
          this.ruleForm.receiveAddress.contactName = this.sendactioAdress.sendcontactName
          this.ruleForm.receiveAddress.contactPhone = this.sendactioAdress.sendcontactPhone
          this.ruleForm.receiveAddress.houseAddress = this.sendactioAdress.sendhouseAddress
          this.ruleForm.receiveAddress.platformAddress = this.sendactioAdress.sendplatformAddress

          if (this.editaWareHouseId&&this.isClickManage) {
            console.log(8888)
            _api
              .savePlatformAddress({
                warehouseId: this.editaWareHouseId,
                contactName:this.sendactioAdress.sendcontactName,
                contactPhone:this.sendactioAdress.sendcontactPhone,
                houseAddress:this.sendactioAdress.sendhouseAddress,
                platformAddress: this.sendactioAdress.sendplatformAddress,
                command:this.sendactioAdress.sendcommand,
                id:this.id
              })
              .then((res) => {
                console.log(res,"res")
                this.pageNum = 1
                this.sendShipaddressDialogVisible1 = false;
                this.getplantAddress();
                this.handleCurrentChange();
                this.sendShipaddressDialogVisible = false
      this.sendactioAdress = {
        sendcontactName: "",
        sendcontactPhone: "",
        sendhouseAddress: "",
        sendplatformAddress: "",
        sendcommand: ""
      };
                this.$refs["sendadrressruleForm"].clearValidate();
              });
          }
        } else {
          return false;
        }
      });
},
    //清空地址
    cleradreww() {
      this.shipaddressDialogVisible = false
      this.actioAdress = {
        contactName: "",
        contactPhone: "",
        houseAddress: "",
        platformAddress: "",
      };

    },
    cleradreww1(){
      this.sendShipaddressDialogVisible = false
      this.sendactioAdress = {
        sendcontactName: "",
        sendcontactPhone: "",
        sendhouseAddress: "",
        sendplatformAddress: "",
        sendcommand: ""
      };
    },
    //编辑地址
    bianArress(data) {
      this.adFlag = "编辑收货地址";
      this.shipaddressDialogVisible = true;
      this.actioAdress.contactName=data.receiveAddress.contactName
      this.actioAdress.contactPhone=data.receiveAddress.contactPhone
      this.actioAdress.platformAddress=data.receiveAddress.platformAddress
      this.actioAdress.houseAddress=data.receiveAddress.houseAddress
      console.log(987)
    },
    //完善收获地址
    wsReAdress(){
      this.actioAdress.contactName=""
      this.actioAdress.contactPhone=''
      this.actioAdress.platformAddress=''
      this.actioAdress.houseAddress=""
      this.adFlag="添加收货地址"
      this.shipaddressDialogVisible = true;
    },
        //编辑地址
        bianArress1(data) {
          console.log(data,123)
      this.senddresstitle1="编辑收货地址"
      console.log(1112)
      this.sendShipaddressDialogVisible1 = true
      this.sendactioAdress.sendcontactName=data.contactName
      this.sendactioAdress.sendcontactPhone=data.contactPhone
      this.sendactioAdress.sendplatformAddress=data.platformAddress
      this.sendactioAdress.sendhouseAddress=data.houseAddress
      this.editaWareHouseId=data.warehouseId
      this.id=data.id
    },
    mybianArress(data){
      this.senddresstitle="编辑发货地址"
      console.log(data,1112)
      this.sendShipaddressDialogVisible = true
      this.sendactioAdress.sendcontactName=data.sendAddress.contactName
      this.sendactioAdress.sendcontactPhone=data.sendAddress.contactPhone
      this.sendactioAdress.sendplatformAddress=data.sendAddress.platformAddress
      this.sendactioAdress.sendhouseAddress=data.sendAddress.houseAddress
    },
    //修改
    detaleDialogClosed() {
      this.command = "";
    },
    //随机数弹框关闭
    randomDialogClosed(){

    },
    // 点击编辑dialog修改地址信息
    forbidSubmit() {
      let that = this
      let obj = {
        receiveAddress: {
          contactName: "",
          contactPhone: "",
          houseAddress: "",
          platformAddress: ""
        },
        sendAddress:{
          contactName: "",
          contactPhone: "",
          houseAddress: "",
          platformAddress: ""
        },
        command: "",
        liabilityerMobile: "",
        liabilityerName: "",
        warehouseName: "",
        warehouseShipName: ""
      }
      obj.receiveAddress.contactName = that.ruleForm.receiveAddress.contactName
      obj.receiveAddress.contactPhone = that.ruleForm.receiveAddress.contactPhone
      obj.receiveAddress.houseAddress = that.ruleForm.receiveAddress.houseAddress
      obj.receiveAddress.platformAddress = that.ruleForm.receiveAddress.platformAddress
      obj.sendAddress.contactName = that.ruleForm.sendAddress.contactName
      obj.sendAddress.contactPhone = that.ruleForm.sendAddress.contactPhone
      obj.sendAddress.houseAddress = that.ruleForm.sendAddress.houseAddress
      obj.sendAddress.platformAddress = that.ruleForm.sendAddress.platformAddress
      obj.command = that.command
      obj.liabilityerMobile = that.ruleForm.liabilityerMobile
      obj.liabilityerName = that.ruleForm.liabilityerName
      obj.warehouseName = that.ruleForm.warehouseName
      obj.warehouseShipName = that.ruleForm.warehouseShipName
      if (!this.editaWareHouseId) {
        http.makeStore(obj).then((res) => {
          if (res.code === 1) {
            that.$message.success("创建成功");
            that.clearRuleForm()
            this.pageNum = 1
            this.handleCurrentChange()
          }
        }).catch(err => {
          console.log(err)
        });
      }
      else {
        obj.id = this.editaWareHouseId
        http.editStore(obj).then((res) => {
          if (res.code === 1) {
            that.$message.success("编辑成功");
            that.clearRuleForm()
            this.pageNum = 1
            this.handleCurrentChange()
          }
        }).catch(err => {
          console.log(err)
        });
      }

    },
    //点击添加地址
    addAdrress() {
      if (this.adressList.length < 6) {
        this.cleradreww();
        this.shipaddressDialogVisible1 = true;
        this.isClickAdd = true
        this.editaWareHouseId=""
      } else {
        this.$message({
          message: "最多可添加6个收货地址",
          type: "warning",
        });
      }
    },
    //删除收货地址
    deteleAdrress(data) {
      console.log(data);
      this.deAdreList.addressId = data.id;
      this.enableShow = true;
    },
    warChange(e) {
      console.log(e);
      this.getplantAddress();
    },
    //获取仓库
    getwarehouseSelectList() {
      _api.getwarehouseSelectList().then((res) => {
        this.entrepotList = res.data;
        if (res.data.length > 0) {
          this.warehouseId = res.data[0].id;
          this.getplantAddress();
        }
      });
    },
    //仓库管理
    modelConfigNext(data) {
      
      _api.queryFormAddress({ warehouseId: data.id }).then(res => {
        this.adressList = res.data
        this.adressDialogVisible = true
        this.warehouseId=data.id
        this.isClickManage=true
      })
    },
    //查询平台商家地址
    getplantAddress() {
      _api.queryFormAddress({ warehouseId: this.warehouseId }).then((res) => {
        this.adressList = res.data;
      });
    },
    //关闭删除
    enableClosed() {
      this.deAdreList.command = "";
      this.formData.command = "";
      this.forommand = "";
      console.log("0000");
    },
    //删除确认
    enableSubmit() {
      console.log(this.deAdreList);
      _api.deplantAddress(this.deAdreList).then((res) => {
        this.deAdreList = {
          addressId: "",
          command: "",
        };
        this.$message({
          message: "删除成功！",
          type: "success",
        });
        this.getplantAddress();
        this.handleCurrentChange();
        this.enableShow = false;
      });
    },
    clearRuleForm() {
      this.storeDialogVisible = false
      this.editShow = false
      this.page.pageNum = 1;
      this.ruleForm.receiveAddress.contactName = ""
      this.ruleForm.receiveAddress.contactPhone = ""
      this.ruleForm.receiveAddress.houseAddress = ""
      this.ruleForm.receiveAddress.platformAddress = ""
      this.command = ""
      this.ruleForm.liabilityerMobile = ""
      this.ruleForm.liabilityerName = ""
      this.ruleForm.warehouseName = ""
      this.ruleForm.warehouseShipName = ""
      this.ruleForm.sendAddress.contactName=""
      this.ruleForm.sendAddress.contactPhone=""
      this.ruleForm.sendAddress. houseAddress=""
      this.ruleForm.sendAddress.platformAddress=""
      this.warehouseId=""
    },
    //打开创建仓库弹框
    openMakeStoreDialog() {
      this.storeDialogVisible = true
      this.storeDialogTitle= "创建仓库"
      this.editShow = false
      this.page.pageNum = 1;
      this.ruleForm.receiveAddress.contactName = ""
      this.ruleForm.receiveAddress.contactPhone = ""
      this.ruleForm.receiveAddress.houseAddress = ""
      this.ruleForm.receiveAddress.platformAddress = ""
      this.ruleForm.sendAddress.contactName = ""
      this.ruleForm.sendAddress.contactPhone = ""
      this.ruleForm.sendAddress.houseAddress = ""
      this.ruleForm.sendAddress.platformAddress = ""
      this.command = ""
      this.ruleForm.liabilityerMobile = ""
      this.ruleForm.liabilityerName = ""
      this.ruleForm.warehouseName = ""
      this.ruleForm.warehouseShipName = ""
    },
    //提交随机数信息
    pushFactorSetSubmit() {
      this.randomShow=true
    },
    //点击配置收货地址编码按钮
    handleBtn(row) {
      console.log(row,999)
      this.randomstoreid=row.id
      this.randomTitle = `配置收货地址编码(${row.warehouseName})`
      this.submitRandomTitle=row.warehouseName
      _api.queryFormAddress({ warehouseId: row.id}).then((res) => {
        this.addressCodeList=res.data
        console.log(res,789)
        this.tableColumns.unshift({
          key: '1314520',
          prop: "name",
          label: "",
        })
        for (let i = 0; i < res.data.length; i++) {
          this.tableColumns.push({
            key: res.data[i].id,
            prop: res.data[i].addressName,
            label: res.data[i].addressName,
            randomMax:res.data[i].randomMax,
            xmContactRandom:res.data[i].xmContactRandom,
            xmAddressRandom:res.data[i].xmAddressRandom,
            ygxContactRandom:res.data[i].ygxContactRandom,
            ygxAddressRandom:res.data[i].ygxAddressRandom,
            chxContactRandom:res.data[i].chxContactRandom,
            chxAddressRandom:res.data[i].chxAddressRandom,
            shanhsContactRandom:res.data[i].shanhsContactRandom,
            shanhsAddressRandom:res.data[i].shanhsAddressRandom,
            platformContactRandom:res.data[i].platformContactRandom,
            platformAddressRandom:res.data[i].platformAddressRandom,
            storeContactRandom:res.data[i].storeContactRandom,
            storeAddressRandom:res.data[i].storeAddressRandom
          })
        }
        console.log(this.tableColumns,"tabcolisum")
      });
      this.pushFactorSetShow = true
    },
    //关闭配置随机数弹框
    handleClose() {
      this.tableColumns = []
    },
    //查看地址
    seeAddress(item){
      for(let i=0;i<this.addressCodeList.length;i++){
        if(this.addressCodeList[i].id==item.key){
          console.log(2)
          this.randomAddress.addressName=this.addressCodeList[i].addressName
          this.randomAddress.contactName=this.addressCodeList[i].contactName
          this.randomAddress.contactPhone=this.addressCodeList[i].contactPhone
          this.randomAddress.platformAddress=this.addressCodeList[i].platformAddress
          this.randomAddress.houseAddress=this.addressCodeList[i].houseAddress
        }
      }
      this.randomAddressDialogVisible=true
    },
    //提交随机数
    randomSubmit(){
      const arry = this.tableColumns.filter(number => number.randomMax);
      let finaarry=[]
      let obj={}
      for(let i=0;i<arry.length;i++){
        if(arry[i].randomMax==0){
          return this.$message.error("输入的随机数不能为0")
        }
        finaarry.push({
          chxContactRandom:arry[i].chxContactRandom,
          chxAddressRandom:arry[i].chxAddressRandom,
          platformContactRandom:arry[i].platformContactRandom,
          platformAddressRandom:arry[i].platformAddressRandom,
          randomMax:arry[i].randomMax,
          shanhsContactRandom:arry[i].shanhsContactRandom,
          shanhsAddressRandom:arry[i].shanhsAddressRandom,
          storeContactRandom:arry[i].storeContactRandom,
          storeAddressRandom:arry[i].storeAddressRandom,
          xmContactRandom:arry[i].xmContactRandom,
          xmAddressRandom:arry[i].xmAddressRandom,
          ygxContactRandom:arry[i].ygxContactRandom,
          ygxAddressRandom:arry[i].ygxAddressRandom,
          id:arry[i].key
        })
      }
      obj.addressList=finaarry
      obj.command=this.randomCommand
      obj.warehouseId=this.randomstoreid
      http.getAddressCode(obj).then(res=>{
        if(res.code==1){
          this.randomShow=false
          this.pushFactorSetShow=false
          this.submitRandomTitle=""
          this.randomCommand=""
          this.$message({
          message: "操作成功",
          type: "success",
        });
   this.pageNum=1
        this.handleCurrentChange();
        }
      }).catch(err=>{

      })
    },
    //打开发货地址弹框
    opensendShipaddressDialog(){
      this.sendShipaddressDialogVisible = true
      this.senddresstitle="添加发货地址"
    },
    //关闭发货地址弹框
    closeshipaddressDialogVisible(){
      this.sendShipaddressDialogVisible = false
      this.senddresstitle=""
      this.sendactioAdress.sendcontactName=""
      this.sendactioAdress.sendcontactPhone=""
      this.sendactioAdress.sendplatformAddress=""
      this.sendactioAdress.sendhouseAddress=""
      this.sendactioAdress.sendcommand=""
      this.$refs["sendadrressruleForm"].clearValidate();
    },
    closeshipaddressDialogVisible22(){
      this.sendShipaddressDialogVisible1 = false
      this.senddresstitle1=""
      this.sendactioAdress.sendcontactName=""
      this.sendactioAdress.sendcontactPhone=""
      this.sendactioAdress.sendplatformAddress=""
      this.sendactioAdress.sendhouseAddress=""
      this.sendactioAdress.sendcommand=""
      this.$refs["sendadrressruleForm1"].clearValidate();
    },
    
        //关闭发货管理地址弹框
        closeshipaddressDialogVisible1(){
      this.sendShipaddressDialogVisible1 = false
      this.senddresstitle1=""
      this.sendactioAdress.sendcontactName=""
      this.sendactioAdress.sendcontactPhone=""
      this.sendactioAdress.sendplatformAddress=""
      this.sendactioAdress.sendhouseAddress=""
      this.sendactioAdress.sendcommand=""
      this.$refs["sendadrressruleForm"].clearValidate();
    },
    //关闭地址管理
    closeAddressManage(){
this.isClickManage=false
this.adressDialogVisible=false
      this.senddresstitle1=""
      this.sendactioAdress.sendcontactName=""
      this.sendactioAdress.sendcontactPhone=""
      this.sendactioAdress.sendplatformAddress=""
      this.sendactioAdress.sendhouseAddress=""
      this.sendactioAdress.sendcommand=""
    },
    handleInput(value){
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        return false
      }
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.top_swith {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-record-dialog {
  .record-title {
    margin-bottom: 20px;
    color: #ff687b;
  }
}

.mt-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;

}

.perfectAddress {
  color: #ff687b;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.box-title {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #333333;
  font-size: 16px;
  font-weight: bold;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: " ";
    width: 4px;
    height: 16px;
    background: #0981ff;
    border-radius: 2px;
  }

}

.ad_cstyle {
  width: 100%;
  padding: 15px;
  min-height: 110px;
  background: #f5f6fa;
  border-radius: 14px;
  margin-bottom: 15px;
  margin-top: -20px;
}

.actionstyle {
  margin: 20px 0;
}

.edit {
  font-size: 14px;
  margin-left: 10px;
  color: #0981FF;
  cursor: pointer;
}

.link {
  text-decoration: underline;
  cursor: pointer;

  &.primary {
    color: #0981ff;
  }
}

.adc_line {
  width: 3px;
  height: 13px;
  background: #0981FF;
  border-radius: 2px;
  margin-right: 5px;
}

.xiugai {
  /deep/ .el-button {
    text-decoration: underline;
    padding: 5px 0 5px 10px;
  }
}

.enable_center {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tip {
  color: #ff687b;
  font-size: 12px;
  margin-bottom: 20px;
}
/deep/.el-table .has-gutter tr th {
    color: #666666;
    font-weight: bold;
    height: 40px;
    padding: 0;
  }
  /deep/.el-table--border th.is-center {
    border-bottom: 1px solid #c4d7fd !important;
  }
</style>